<template>
  <div class="content herbs flex flex-col gap-4">
    <div class="flex items-center justify-between gap-2 w-100">
      <div class="flex gap-8">
        <div class="flex flex-column gap-1">
          <div>Group:</div>
          <b-form-select
            v-model="filters.groupid"
            :options="options.groupid"
            size="sm"
            @input="fetchHerbs()"
            text-field="Name"
            value-field="Id"
          />
        </div>

        <div class="flex flex-column gap-1">
          <div>Search:</div>
          <b-form-input
            v-model="filters.name"
            placeholder="Plant name"
            size="sm"
            autocomplete="off"
            trim
            type="search"
            debounce="500"
            @change="fetchHerbs()"
            class="search-field"
          ></b-form-input>
        </div>
      </div>

      <div class="flex gap-2">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="createHerb()"
        >
          Add new plant
        </b-button>
      </div>
    </div>

    <table class="table table-google">
      <thead>
        <tr>
          <th class="text-nowrap">ID</th>
          <th class="text-nowrap">Plant</th>
          <th class="text-nowrap">Group</th>
          <th class="text-nowrap row-fit">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="herb in herbs" :key="herb.id">
          <td class="row-fit">{{ leftPadZero(herb.Id, idTargetLength) }}</td>
          <td>{{ herb.Name }}</td>
          <td>{{ herb.GroupName }}</td>
          <td>
            <div class="flex gap-2">
              <b-button
                variant="primary"
                size="sm"
                @click="editHerb(herb)"
                :disabled="loadingCount > 0"
              >
                Edit
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                @click="deleteHerb(herb)"
                :disabled="loadingCount > 0"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>

        <tr v-if="herbs.length === 0">
          <td colspan="4">
            <span v-if="loadingCount > 0">Loading..</span>
            <span v-else>No plants found.</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  getArrayTargetLength,
  leftPadZero,
} from '@/helper';

export default {
  name: 'Herbs',
  components: {},
  computed: {
    idTargetLength() {
      return getArrayTargetLength(this.herbs.map((herb) => herb.Id));
    },
  },
  data() {
    return {
      loadingCount: 0,
      herbs: [],
      groups: [],
      filters: {
        groupid: null,
        name: '',
      },
      options: {
        groupid: [],
      },
    };
  },
  methods: {
    fetchHerbs() {
      const params = {};
      if (this.filters.groupid) {
        params.groupids = [this.filters.groupid];
      }
      if (this.filters.name.length > 0) {
        params.name = this.filters.name;
      }
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'herbs' })
        .send({ params })
        .then((res) => {
          this.herbs = res.body;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchGroups() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'groups' })
        .then((res) => {
          this.options.groupid = [
            { Id: null, Name: '' },
            ...res.body,
          ];
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchHerbs();
      this.fetchGroups();
    },
    editHerb(herb) {
      this.$router.push({ name: 'Herb', params: { herbid: herb.Id } });
    },
    createHerb() {
      this.$router.push({ name: 'Herb', params: { herbid: 'new' } });
    },
    deleteHerbRecord(herb) {
      const params = {
        herbids: [herb.Id],
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'herbsDel' })
        .send({ params })
        .then(() => {
          this.fetchHerbs();
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteHerbIdeals(herb) {
      this.loadingCount++;
      this.$http
        .delete(`/ideals/${herb.Id}`)
        .then(() => {
          this.deleteHerbRecord(herb);
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteHerb(herb) {
      const confirmed = confirm(`Do you really wish to delete herb '${herb.Name}'?`);
      if (!confirmed) {
        return;
      }
      this.deleteHerbIdeals(herb);
    },
    leftPadZero(value, targetLength) {
      return leftPadZero(value, targetLength);
    },
  },
  created() {
    this.fetchAll();
  },
};
</script>

<style lang="scss" scoped>
  .herbs {
    max-width: 800px;
  }
</style>
